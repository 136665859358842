<template>
  <section id="exhibitions-currentupcoming">
    <ExhibitionCurrent :exhibitions="exhibitions" />
    <ExhibitionUpcoming :exhibitions="exhibitions" />
    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>
  </section>
</template>

<script>
import ExhibitionCurrent from '../components/exhibitions/ExhibitionCurrent';
import ExhibitionUpcoming from '../components/exhibitions/ExhibitionUpcoming';
import gql from "graphql-tag";

const getExhibitions = gql `
  query Exhibitions {
    exhibitions(sort: "date_start:asc") {
      id
      slug
      title
      subtitle
      title_main_artist
      date_start
      date_end
      photo_cover {
        formats
        url
      }
      artists {
        first_name
        last_name
      }
    }
  }
`;

const getArtists = gql `
  query Artists {
    artists {
      first_name
      last_name
      slug
      country_en
      country_no
      website
    }
  }
`;

export default {
    metaInfo() {
        return { title: this.$t("nav.currentUpcoming") }
    },
    apollo: {
        exhibitions: {
            query: getExhibitions
        },
        artists: {
            query: getArtists
        }
    },
    components: {
        ExhibitionCurrent,
        ExhibitionUpcoming
    },
    data() {
        return {
            exhibitions: []
        };
    }
}
</script>