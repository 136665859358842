<template>
  <section id="single-interview">
    <div
      v-if="interview"
      class="defaultcontent-grid"
    >
      <div class="singlepost-grid-primary">
        <div id="post-content">
          <div
            id="embed"
            class="embed-wrapper"
          >
            <youtube
              v-if="interview[0].youtube_id"
              ref="youtube"
              :video-id="interview[0].youtube_id"
              fit-parent="true"
            />
          </div>

          <div id="post-title">
            <p
              v-if="$i18n.locale === 'nb' || interview[0].title_en === '' || interview[0].title_en === null"
              class="title-default"
            >
              {{ interview[0].title_no }}
            </p>
            <p
              v-else
              class="title-default"
            >
              {{ interview[0].title_en }}
            </p>
          </div>
          <div id="post-text">
            <MarkedParser
              v-if="$i18n.locale === 'nb'"
              :marked-content="interview[0].content_no"
              class="markdown"
            />
            <MarkedParser
              v-else
              :marked-content="interview[0].content_en"
              class="markdown"
            />
          </div>
        </div>
        <div
          id="post-meta"
          class="metatext"
        >
          <div id="post-published">
            <p>{{ interview[0].manual_post_date | formatDateFull }}</p>
          </div>
          <ul
            v-if="interview[0].rel_exh"
            id="post-related"
          >
            <p class="boldtext">
              <span>{{ $tc('posts.relatedExhibition', 1) }}</span>
            </p>
            <li>
              <router-link :to="/exhibitions/ + interview[0].rel_exh.slug">
              <!-- <MarkedParser :marked-content="interview[0].rel_exh.title" /> -->
              <!-- <MarkedParser :marked-content="interview[0].rel_exh.subtitle" /> -->
              <span v-if="interview[0].rel_exh.title_main_artist">{{ interview[0].rel_exh.title_main_artist }}: </span> <span>{{ interview[0].rel_exh.title }}</span> <span v-if="interview[0].rel_exh.subtitle">{{ interview[0].rel_exh.subtitle }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    
      <div class="singlepost-grid-secondary">
        <!-- <div id="post-photocover">
                    <figure v-if="interview[0].photo_cover">
                        <img :data-src="api_url + interview[0].photo_cover.formats.small.url" alt="" class="lazy">
                        <figcaption>{{ interview[0].photo_cover.caption }}</figcaption>
                    </figure>
                </div> -->
        <div
          v-for="image in interview[0].photo_gallery"
          id="post-gallery"
          :key="image.id"
        >
          <figure>
            <img
              v-if="image.formats.large"
              :data-src="api_url + image.formats.large.url"
              alt=""
              class="lazy"
            >
            <img
              v-else-if="image.formats.medium"
              :data-src="api_url + image.formats.medium.url"
              alt=""
              class="lazy"
            >
            <img
              v-else
              :data-src="api_url + image.formats.small.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ image.caption }}</figcaption>
          </figure>
        </div>
      </div>
    </div>
    <BaseBtnBack />
  </section>
</template>

<script>
import MarkedParser from '../components/MarkedParser'
import BaseBtnBack from '../components/base/BaseBtnBack'

import gql from "graphql-tag"
import LazyLoad from 'vanilla-lazyload'

const getInterview = gql `
  query Interview ($slug: String!) {
    interviews(limit: 1, where: { slug: $slug }) {
      title_en
      title_no
      manual_post_date
      content_en
      content_no
      contentShort_no
      contentShort_en
      youtube_id
      photo_cover {
          formats
          caption
      }
      photo_gallery {
          formats
          caption
      }
      rel_exh {
          title
          subtitle
          title_main_artist
          slug
      }
    }
  }
`;

export default {
    apollo: {
        interview: {
            query: getInterview,
            variables() {
                return {
                    slug: this.$route.params.slug
                };
            },
            update: data => data.interviews
        }
    },
    components: {
        MarkedParser,
        BaseBtnBack
    },
    data() {
        return {
            ogTitle: '',
            ogDesc: '',
            ogImage: '',
            interview: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    metaInfo() {
        return {
            title: this.ogTitle,
            meta: [
                { property: 'og:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: this.api_url + this.ogImage, vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: this.api_url + this.ogImage, vmid: 'twitter:card' },
                { property: 'twitter:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    mounted() {
        this.pageLazyLoad = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 100,
        });
    },
    updated() {
        let pageTitle = this.interview[0].title_no;
        let pageDescription = this.interview[0].contentShort_no;
        let pageCover = this.interview[0].photo_cover.formats.medium.url;

        if (pageTitle) {
            this.ogTitle = pageTitle
        }
        if (pageDescription) {
            this.ogDesc = pageDescription
        }
        if (pageCover) {
            this.ogImage = pageCover
        }

        this.pageLazyLoad.update();
    },
    methods: {
        player() {
            return this.$refs.youtube.player
        },
    }
};
</script>
