<template>
    <div v-if="ifPast.length > 0" id="exhibitions-past" class="defaultcontent-grid--fullwidth">
        <h1 class="section-header">
            {{ $t("headers.archive") }}
        </h1>
        <div id="collection">
            <ListComponent :list-content="ifPast" />
        </div>
    </div>
</template>

<script>
import ListComponent from '../ListComponent'
import { format, differenceInDays } from 'date-fns'

export default {
    components: {
        ListComponent
    },
    props: {
        exhibitions: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    computed: {
        today() {
            return format(new Date(), "yyyy-MM-dd")
        },
        ifPast: function() {
            let filteredExhibitions = this.exhibitions.filter((exhibition) => { return exhibition.date_end < this.today });
            return filteredExhibitions.reverse();
        },
    },
    methods: {
        closingSoon: function(value) {
            return differenceInDays(new Date(), new Date(value));
        },
        togglePreview: function(event) {
            if (event) {
                const preview = event.target.parentNode.parentNode.nextSibling;
                const toggler = event.target;
                if (preview.classList.contains('closed')) {
                    preview.classList.toggle('closed');
                    toggler.classList.remove('down');
                    toggler.classList.add('up');
                } else {
                    preview.classList.toggle('closed');
                    toggler.classList.remove('up');
                    toggler.classList.add('down');
                }
            }
        }
    }
};
</script>
