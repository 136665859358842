<template>
  <section
    v-if="exhibitions"
    id="exhibitions-page"
  >
    <ExhibitionsList :exhibitions="exhibitions" />
    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>
  </section>
</template>

<script>
import ExhibitionsList from "../components/exhibitions/ExhibitionsList.vue";
import gql from "graphql-tag";

const getExhibitions = gql `
  query Exhibitions {
    exhibitions(sort: "date_start:asc") {
      id
      slug
      title
      date_start
      date_end
      contentShort_no
      contentShort_en
      photo_cover {
        formats
        url
      }
      artists {
        first_name
        last_name
      }
    }
  }
`;

export default {
    apollo: {
        exhibitions: {
            query: getExhibitions,
            variables() {}
        }
    },
    components: {
        ExhibitionsList
    },
    metaInfo() {
        return { title: this.$t("nav.exhibitions") }
    },
    data() {
        return {
            exhibitions: []
        };
    }
};
</script>
