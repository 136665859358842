<template>
    <div v-if="exhibitions" id="exhibitions-current" class="defaultcontent-grid--fullwidth">
        <swiper v-if="ifCurrent.length >= 1 || ifUpcoming.length >= 1" id="collection" :data-amount="ifCurrent.length" class="swiper" :options="swiperOption">
            <swiper-slide v-for="exhibition in ifCurrent" :key="exhibition.slug" class="swiper-slide">
                <router-link :to="{ path: '/exhibitions/' + exhibition.slug }">
                    <div v-if="exhibition.photo_cover" class="item-thumb" :style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.formats.large.url + ')' }">
                        <!-- <div v-if="exhibition.photo_cover" class="item-thumb" v-bind:style="{ backgroundImage: '-webkit-image-set( url(' + api_url + exhibition.photo_cover.formats.large.url + ') 1280px, url(' + api_url + exhibition.photo_cover.url + ') 1920px )' }" > -->
                        <div class="item-details">
                            <p id="title" class="title-header">
                                <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}:<br></span>
                                <span>{{ exhibition.title }}<br></span>
                                <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                            </p>
                            <!-- <MarkedParser id="title" class="title-header" :marked-content="exhibition.title" /> -->
                            <p id="date" class="date-header">
                                <span>{{ exhibition.date_start | formatDateFull }}</span>
                                <span>–</span>
                                <span>{{ exhibition.date_end | formatDateFull }}</span>
                            </p>
                        </div>
                        <div v-if="closingSoon(exhibition.date_end) >= '-7'" class="rounded-btn--red">
                            {{ $t('buttons.closingSoon') }}
                        </div>
                    </div>
    
                    <!-- <div v-else-if="exhibition.photo_cover.formats && exhibition.photo_cover.formats.large" class="item-thumb" v-bind:style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.formats.large.url + ')' }">
                            <div class="item-details">
                                <p id="title" class="title-header">{{ exhibition.title }}</p>
                                <p id="date" class="date-header">
                                    <span>{{ exhibition.date_start | formatDateFull}}</span>
                                    <span>–</span>
                                    <span>{{exhibition.date_end | formatDateFull}}</span>
                                </p>
                            </div>
                            <div class="rounded-btn--red" v-if="closingSoon(exhibition.date_end) >= '-7'">{{$t('buttons.closingSoon')}}</div>
                        </div>
    
                        <div v-else-if="exhibition.photo_cover.formats && exhibition.photo_cover.formats.medium" class="item-thumb" v-bind:style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.formats.medium.url + ')' }">
                            <div class="item-details">
                                <p id="title" class="title-header">{{ exhibition.title }}</p>
                                <p id="date" class="date-header">
                                    <span>{{ exhibition.date_start | formatDateFull}}</span>
                                    <span>–</span>
                                    <span>{{exhibition.date_end | formatDateFull}}</span>
                                </p>
                            </div>
                            <div class="rounded-btn--red" v-if="closingSoon(exhibition.date_end) >= '-7'">{{$t('buttons.closingSoon')}}</div>
                        </div> -->
    
                    <div v-else class="no-swiperThumb item-thumb">
                        <div class="item-details">
                            <p id="title" class="title-header">
                                <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}:<br></span>
                                <span>{{ exhibition.title }}<br></span>
                                <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                            </p>
                            <!-- <MarkedParser id="title" class="title-header" :marked-content="exhibition.title" /> -->
                            <p id="date" class="date-header">
                                <span>{{ exhibition.date_start | formatDateFull }}</span>
                                <span>–</span>
                                <span>{{ exhibition.date_end | formatDateFull }}</span>
                            </p>
                        </div>
                    </div>
                </router-link>
            </swiper-slide>
    
            <swiper-slide v-for="exhibition in ifUpcoming" :key="exhibition.slug" class="swiper-slide">
                <router-link :to="{ path: '/exhibitions/' + exhibition.slug }">
                    <div v-if="exhibition.photo_cover" class="item-thumb" :style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.url + ')' }">
                        <div class="item-details">
                            <p id="title" class="title-header">
                                <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}:<br></span>
                                <span>{{ exhibition.title }}<br></span>
                                <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                            </p>
                            <!-- <MarkedParser id="title" class="title-header" :marked-content="exhibition.title" /> -->
                            <p id="date" class="date-header">
                                <span>{{ exhibition.date_start | formatDateFull }}</span>
                                <span>–</span>
                                <span>{{ exhibition.date_end | formatDateFull }}</span>
                            </p>
                        </div>
                        <div v-if="openingSoon(exhibition.date_start) >= '-30' && openingSoon(exhibition.date_start) < '0'" class="rounded-btn--green">
                            {{ $t('buttons.openingSoon') }}
                        </div>
                    </div>
    
                    <!-- <div v-else-if="exhibition.photo_cover.formats && exhibition.photo_cover.formats.large" class="item-thumb" v-bind:style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.formats.large.url + ')' }">
                            <div class="item-details">
                                <p id="title" class="title-header">{{ exhibition.title }}</p>
                                <p id="date" class="date-header">
                                    <span>{{ exhibition.date_start | formatDateFull}}</span>
                                    <span>–</span>
                                    <span>{{exhibition.date_end | formatDateFull}}</span>
                                </p>
                            </div>
        
                            <div class="rounded-btn--green" v-if="openingSoon(exhibition.date_start) >= '-30' && openingSoon(exhibition.date_start) < '0'">{{$t('buttons.openingSoon')}}</div>
                        </div>
    
                        <div v-else-if="exhibition.photo_cover.formats && exhibition.photo_cover.formats.medium" class="item-thumb" v-bind:style="{ backgroundImage: 'url(' + api_url + exhibition.photo_cover.formats.medium.url + ')' }">
                            <div class="item-details">
                                <p id="title" class="title-header">{{ exhibition.title }}</p>
                                <p id="date" class="date-header">
                                    <span>{{ exhibition.date_start | formatDateFull}}</span>
                                    <span>–</span>
                                    <span>{{exhibition.date_end | formatDateFull}}</span>
                                </p>
                            </div>
        
                            <div class="rounded-btn--green" v-if="openingSoon(exhibition.date_start) >= '-30' && openingSoon(exhibition.date_start) < '0'">{{$t('buttons.openingSoon')}}</div>
                        </div> -->
    
                    <div v-else class="no-swiperThumb item-thumb">
                        <div class="item-details">
                            <p id="title" class="title-header">
                                <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}:<br></span>
                                <span>{{ exhibition.title }}<br></span>
                                <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                            </p>
                            <!-- <MarkedParser id="title" class="title-header" :marked-content="exhibition.title" /> -->
                            <p id="date" class="date-header">
                                <span>{{ exhibition.date_start | formatDateFull }}</span>
                                <span>–</span>
                                <span>{{ exhibition.date_end | formatDateFull }}</span>
                            </p>
                        </div>
                        <div v-if="openingSoon(exhibition.date_start) >= '-30' && openingSoon(exhibition.date_start) < '0'" class="rounded-btn--white">
                            {{ $t('buttons.openingSoon') }}
                        </div>
                    </div>
                </router-link>
            </swiper-slide>
    
            <div v-show="ifCurrent.length > 1 || ifCurrent.length + ifUpcoming.length > 1" slot="pagination" class="swiper-pagination" />
            <div v-show="ifCurrent.length > 1 || ifCurrent.length + ifUpcoming.length > 1" slot="button-prev" class="swiper-button-prev-unique gallery--left-side" />
            <div v-show="ifCurrent.length > 1 || ifCurrent.length + ifUpcoming.length > 1" slot="button-next" class="swiper-button-next-unique gallery--right-side" />
        </swiper>
    
        <div v-else-if="ifCurrent.length === 0" class="no-exhibition-placeholder">
            <p />
        </div>
    
        <h1 class="section-header">
            <p></p>
            <!-- {{ $tc("headers.currentExhibitions", ifCurrent.length) }} -->
            <span v-if="$route.path == '/'"><router-link :to="{ path: '/exhibitions' }" class="square-btn--default">{{ $t("buttons.moreExhibitions") }}</router-link></span>
        </h1>
    </div>
</template>

<script>
// import MarkedParser from '@/components/MarkedParser'
import { format, differenceInDays } from 'date-fns'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper,
        SwiperSlide,
        // MarkedParser
    },
    props: {
        exhibitions: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            filteredExhibitions: 0,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 25,
                resistanceRatio: 0,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next-unique',
                    prevEl: '.swiper-button-prev-unique'
                }
            }
        };
    },
    computed: {
        today() {
            return format(new Date(), "yyyy-MM-dd")
        },
        ifCurrent: function() {
            let filteredExhibitions = this.exhibitions.filter((exhibition) => { return exhibition.date_end >= this.today && exhibition.date_start <= this.today });
            return filteredExhibitions.reverse();
        },
        ifUpcoming: function() {
            let filteredExhibitions = this.exhibitions.filter((exhibition) => { return exhibition.date_start > this.today });
            return filteredExhibitions.slice(0, 1);
        },
    },
    methods: {
        closingSoon: function(value) {
            return differenceInDays(new Date(), new Date(value));
        },
        openingSoon: function(value) {
            return differenceInDays(new Date(), new Date(value));
        }
    }
};
</script>