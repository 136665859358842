<template>
    <div class="slidepanel-grid">
        <div class="slidepanel-grid--full slidepanel-title">
            <p v-if="slideContent.title">
                {{ slideContent.title }}
            </p>
        </div>
    
        <div v-if="slideContent.photo_gallery.length >= 1" class="slidepanel-grid--full">
            <swiper id="collection" ref="mySwiper" class="swiper" :options="swiperOption">
                <swiper-slide v-for="image in slideContent.photo_gallery" :key="image.name" class="swiper-slide">
                    <img v-if="image.formats.thumbnail.width >= image.formats.thumbnail.height" class="image-landscape swiper-lazy" :src="apiUrl + image.formats.medium.url">
                    <img v-else-if="image.formats.thumbnail.width <= image.formats.thumbnail.height" class="image-portrait swiper-lazy" :src="apiUrl + image.formats.medium.url">
                    <figcaption v-if="image.caption">
                        {{ image.caption }}
                    </figcaption>
                    <!-- <div class="swiper-lazy-preloader"></div> -->
                </swiper-slide>
    
                <div v-show="slideContent.photo_gallery.length > 1" slot="button-prev" class="swiper-button-prev-unique gallery--left-side" />
                <div v-show="slideContent.photo_gallery.length > 1" slot="button-next" class="swiper-button-next-unique gallery--right-side" />
            </swiper>
            <div v-if="slideContent.photo_gallery.length > 1" slot="pagination" class="swiper-pagination metatext" />
        </div>
    
        <div class="slidepanel-grid--full">
            <MarkedParser v-if="$i18n.locale === 'nb' && slideContent.content_no" :marked-content="slideContent.content_no" class="markdown" />
            <MarkedParser v-else :marked-content="slideContent.content_en" class="markdown" />
    
            <div id="pub-meta">
                <ul v-if="slideContent.rel_exh.length >= 1" class="pub-exhibitions metatext">
                    <p>
                        <span><strong>{{ $tc('posts.relatedExhibition', slideContent.rel_exh.length) }}</strong></span>
                    </p>
                    <li v-for="exhibition in slideContent.rel_exh" :key="exhibition.id">
                        <router-link :to="{ path: '/exhibitions/' + exhibition.slug }">
                            <div>
                                <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}: </span> <span>{{ exhibition.title }}</span> <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                            </div>
                        </router-link>
                    </li>
                </ul>
    
                <ul v-if="slideContent.publisher || slideContent.author || slideContent.year || slideContent.details.length >= 1" class="extra-details metatext">
                    <li v-if="slideContent.publisher">
                        <p>
                            <strong>{{ $t('publication.publisher') }}</strong>
                        </p>
                        <p>{{ slideContent.publisher }}</p>
                    </li>
    
                    <li v-if="slideContent.author">
                        <p><strong>{{ $t('publication.author') }}</strong></p>
                        <p>{{ slideContent.author }}</p>
                    </li>
    
                    <li v-if="slideContent.year">
                        <p><strong>{{ $t('publication.year') }}</strong></p>
                        <p>{{ slideContent.year }}</p>
                    </li>
    
                    <li v-for="detail in slideContent.details" :key="detail.id">
                        <p v-if="$i18n.locale === 'nb' || detail.key_en === null">
                            <strong>{{ detail.key_no }}</strong>
                        </p>
                        <p v-else>
                            <strong>{{ detail.key_en }}</strong>
                        </p>
                        <p v-if="$i18n.locale === 'nb' || detail.value_en === null">
                            {{ detail.value_no }}
                        </p>
                        <p v-else>
                            {{ detail.value_en }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        MarkedParser,
        Swiper,
        SwiperSlide,
    },
    props: {
        apiUrl: {
            type: String,
            default: ''
        },
        slideContent: {
            type: Object,
            default: () => ({})
        }
    },
    data: function() {
        return {
            swiperOption: {
                slidesPerView: 1,
                resistanceRatio: 0,
                // preloadImages: true,
                // autoplay: true,
                loop: true,
                // lazy: {
                //     loadPrevNext: true,
                //     loadPrevNextAmount: 1
                // },
                navigation: {
                    nextEl: '.swiper-button-next-unique',
                    prevEl: '.swiper-button-prev-unique'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
            }
        }
    },
    updated() {
        const swiperWrapper = document.querySelector('.swiper');
        if (swiperWrapper) {
            swiperWrapper.swiper.init();
        }
    },
}
</script>