<template>
    <div class="slidepanel-grid">
        <div class="slidepanel-grid--full slidepanel-title">
            <p v-if="slideContent.first_name">
                {{ slideContent.first_name }} {{ slideContent.last_name }} <span v-if="slideContent.year_of_birth">({{ slideContent.year_of_birth }})</span>
            </p>
            <p v-else>
                {{ slideContent.last_name }} <span v-if="slideContent.year_of_birth">({{ slideContent.year_of_birth }})</span>
            </p>
        </div>
    
        <div v-if="slideContent.country_no" class="slidepanel-grid--left metatext">
            <h3 class="metatext-header">
                {{ $t('slidepanel.nationality') }}
            </h3>
            <p v-if="$i18n.locale === 'en'">
                {{ slideContent.country_en }}
            </p>
            <p v-else>
                {{ slideContent.country_no }}
            </p>
        </div>
    
        <div v-if="slideContent.website" class="slidepanel-grid--middle metatext">
            <h3 class="metatext-header">
                {{ $t('slidepanel.website') }}
            </h3>
            <a ref="noreferrer" :href="slideContent.website | cleanUrl " target="_blank">{{ slideContent.website | splitUrl }}</a>
        </div>
    
        <div v-if="slideContent.instagram" class="slidepanel-grid--right metatext">
            <h3 class="metatext-header">
                Instagram
            </h3>
            <a ref="noreferrer" :href="slideContent.instagram | cleanUrl" target="_blank">{{ slideContent.instagram | splitIG }}</a>
        </div>
    
        <div v-if="slideContent.photo_cover" class="slidepanel-grid--full">
            <figure v-if="slideContent.photo_cover.formats.medium">
                <img v-if="slideContent.photo_cover.formats.thumbnail.width >= slideContent.photo_cover.formats.thumbnail.height" class="image-landscape" :src="apiUrl + slideContent.photo_cover.formats.medium.url" :alt="'nsfs-' + slideContent.photo_cover.name">
                <img v-else-if="slideContent.photo_cover.formats.thumbnail.width <= slideContent.photo_cover.formats.thumbnail.height" class="image-portrait" :src="apiUrl + slideContent.photo_cover.formats.medium.url" :alt="'nsfs-' + slideContent.photo_cover.name">
            </figure>
    
            <figure v-else-if="slideContent.photo_cover.formats.small">
                <img v-if="slideContent.photo_cover.formats.thumbnail.width >= slideContent.photo_cover.formats.thumbnail.height" class="image-landscape" :src="apiUrl + slideContent.photo_cover.formats.small.url" :alt="'nsfs-' + slideContent.photo_cover.name">
                <img v-else-if="slideContent.photo_cover.formats.thumbnail.width <= slideContent.photo_cover.formats.thumbnail.height" class="image-portrait" :src="apiUrl + slideContent.photo_cover.formats.small.url" :alt="'nsfs-' + slideContent.photo_cover.name">
            </figure>
    
            <figure v-else-if="slideContent.photo_cover.formats.thumbnail">
                <img v-if="slideContent.photo_cover.formats.thumbnail.width >= slideContent.photo_cover.formats.thumbnail.height" class="image-landscape" :src="apiUrl + slideContent.photo_cover.formats.thumbnail.url" :alt="'nsfs-' + slideContent.photo_cover.name">
                <img v-else-if="slideContent.photo_cover.formats.thumbnail.width <= slideContent.photo_cover.formats.thumbnail.height" class="image-portrait" :src="apiUrl + slideContent.photo_cover.formats.thumbnail.url" :alt="'nsfs-' + slideContent.photo_cover.name">
            </figure>
    
            <figcaption v-if="slideContent.photo_cover.caption">
                {{ slideContent.photo_cover.caption }}
            </figcaption>
        </div>
    
        <ul class="slidepanel-grid--full metatext artistexhibition-grid">
            <li v-for="exhibition in slideContent.exhibitions" :key="exhibition.slug">
                <router-link :to="{ path: '/exhibitions/' + exhibition.slug }">
                    <div class="boldtext">
                        <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}: </span> <span>{{ exhibition.title }}</span> <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
                    </div>
                    <span id="date">{{ exhibition.date_start | formatDateFull }}–{{ exhibition.date_end | formatDateFull }}</span>
                </router-link>
            </li>
        </ul>
        
        <ul v-if="slideContent.extra_details.length >= 1"
            class="extra-details metatext">
            <li v-for="detail in slideContent.extra_details" :key="detail.id">
                <p v-if="$i18n.locale === 'nb' || detail.key_en === null">
                    <strong>{{ detail.key_no }}</strong>
                </p>
                <p v-else>
                    <strong>{{ detail.key_en }}</strong>
                </p>
                <p v-if="$i18n.locale === 'nb' || detail.value_en === null">
                    {{ detail.value_no }}
                </p>
                <p v-else>
                    {{ detail.value_en }}
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        apiUrl: {
            type: String,
            default: ''
        },
        slideContent: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>