<template>
  <section
    v-if="interviews"
    id="interview-overview"
    class="card-grid defaultcontent-grid--fullwidth"
  >
    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>

    <InterviewsList :interviews="interviews" />

    <div id="loadmore">
      <button
        v-if="morePosts"
        class="square-btn--default"
        @click="loadMore"
      >
        {{ $t('buttons.loadMorePosts') }}
      </button>
    </div>
  </section>
</template>

<script>
import InterviewsList from '../components/interviews/InterviewsList'
import gql from 'graphql-tag'

const getInterviews = gql `
  query Interviews($start: Int!, $limit: Int!) {
    interviews(sort: "manual_post_date:desc",         start: $start, limit: $limit) {
            id
            title_en
            title_no
            slug
            content_no
            content_en  
            contentShort_no
            contentShort_en
            manual_post_date
            photo_cover {
                formats
            }
        }
    }
`;

const getAllInterviews = gql `
  query totalCount {
    totalCount: interviews {
      id
    }
  }
`;

export default {
    apollo: {
        interviews: {
            query: getInterviews,
            variables() {
                return {
                    limit: 9,
                    start: 0,
                    target: this.targetCat
                }
            },
        },
        totalCount: {
            query: getAllInterviews,
            result({ data }) {
                this.totalCount = data.totalCount
            },
        },
    },
    components: {
        InterviewsList
    },
    data: function() {
        return {
            totalCount: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    metaInfo() {
        return { 
            title: this.$t("nav.nitjaOnline"),
        }
    },
    computed: {
        morePosts() {
            return this.totalCount.length > this.interviews.length
        },
    },
    methods: {
        loadMore() {
            this.$apollo.queries.interviews.fetchMore({
                variables: {
                    start: this.interviews.length
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    return Object.assign({}, previousResult, {
                        interviews: [...previousResult.interviews, ...fetchMoreResult.interviews]
                    })
                }
            })
        },
    },

};
</script>
