<template>
    <div id="artists-overview">
        <h1 class="section-header">
            {{ $t("headers.artistsList") }}
        </h1>
    
        <div v-if="$apollo.loading">
            <p>{{ $t('headers.loading') }}</p>
        </div>
    
        <div id="collection" class="artists-grid">
            <ul v-for="(value, key) in sortedArtists" :id="'artists-' + key" :key="value.last_name">
                <p class="boldtext">
                    {{ key }}
                </p>
                <li v-for="artist in value" :key="artist.slug">
                    <span :viewArtist="artist.slug" @click.self="pushToPanel(artist.slug, 'getArtist')">{{ artist.first_name }} {{ artist.last_name }}</span>
                </li>
            </ul>
        </div>
    
        <SlidePanel ref="panel" />
    </div>
</template>

<script>
import SlidePanel from "../SlidePanel"
import _ from "lodash"

export default {
    components: {
        SlidePanel
    },
    props: {
        artists: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            isPanelVisible: false
        };
    },
    computed: {
        sortedArtists: function() {
            const collectedArtists = this.artists;
            const newCollection = []
            collectedArtists.every(artist => {
                let firstName = artist['first_name'];
                let lastName = artist['last_name'];
                let artistSlug = artist['slug'];
                let splitLast = artist['last_name'].split(" ")
                let getSplitted = splitLast[splitLast.length - 1]
                newCollection.push({
                    'first_name': firstName,
                    'last_name': lastName,
                    'slug': artistSlug,
                    'split_last_name': getSplitted,
                })
                return true
            })

            const sortedArray = _.groupBy(_.sortBy(newCollection, 'split_last_name'), item => {
                const latinize = require('latinize');
                let sortOnName = item.split_last_name

                _.extend(latinize.characters, { 'Æ': 'Æ', 'Ø': 'Ø', 'Ö': 'Ø', 'Å': 'Å', 'Ä': 'Å' });
                return latinize(sortOnName.charAt(0));
            })
            return sortedArray
        }
    },
    methods: {
        pushToPanel: function(passSlug, getType) {
            this.$refs.panel.openPanel(passSlug, getType);
        }
    }
};
</script>
