<template>
    <div id="exhibitions-overview" class="defaultcontent-grid">
        <ExhibitionCurrent :exhibitions="exhibitions" />
        <ExhibitionUpcoming :exhibitions="exhibitions" />
        <ExhibitionPast :exhibitions="exhibitions" />
    </div>
</template>

<script>
import ExhibitionCurrent from "./ExhibitionCurrent"
import ExhibitionUpcoming from "./ExhibitionUpcoming"
import ExhibitionPast from "./ExhibitionPast"

export default {
    components: {
        ExhibitionCurrent,
        ExhibitionUpcoming,
        ExhibitionPast,
    },
    props: {
        exhibitions: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    }
};
</script>
