<template>
    <div id="slidepanel" class="">
        <div id="slidepanel-content">
            <button id="close-panel" class="close-panel" @click="closePanel">
                <i class="close" />
              </button>
    
            <div v-if="pubLoaded === true">
                <SlidePublication v-for="publication in publications" :key="publication.id" :slide-content="publication" :api-url="api_url" />
            </div>
    
            <div v-if="curatorLoaded === true">
                <SlideCurator v-for="curator in curators" :key="curator.id" :slide-content="curator" :api-url="api_url" />
            </div>
    
            <div v-if="artistLoaded === true">
                <SlideArtist v-for="artist in artists" :key="artist.id" :slide-content="artist" :api-url="api_url" />
            </div>
        </div>
    </div>
</template>

<script>
import SlidePublication from '@/containers/SlidePublication'
import SlideCurator from '@/containers/SlideCurator'
import SlideArtist from '@/containers/SlideArtist'

import gql from 'graphql-tag'


const getArtists = gql `query Artists ($artistSlug: String!) {
    artists(where: {slug: $artistSlug}) {
        id
        slug
        first_name
        last_name
        country_en
        country_no
        website
        instagram
        year_of_birth
        extra_details {
            key_no
            value_no
            key_en
            value_en
        }
        extra_artist_category
        photo_cover {
            formats
            caption
            name
        }
        exhibitions {
            slug
            title
            subtitle
            title_main_artist
            date_start
            date_end
        }
    }
}`

const getCurators = gql `query Curators ($curatorSlug: String!) {
    curators(where: {slug: $curatorSlug}) {
        slug
        first_name
        last_name
        year_of_birth
        rel_exh {
            slug
            title
            subtitle
            title_main_artist
            date_start
            date_end
        }
    }
}`

const getPublications = gql `query Publications ($pubSlug: String!) {
    publications(where: {slug: $pubSlug}) {
        id
        title
        slug
        year
        publisher
        author
        content_no
        content_en
        rel_exh {
            id
            title
            subtitle
            title_main_artist
            slug
        }
        photo_gallery {
            formats
            caption
            name
        }
        details {
            key_no
            key_en
            value_no
            value_en
        }
    }
}`

export default {
    apollo: {
        curators: {
            query: getCurators,
            variables() {
                return {
                    curatorSlug: this.curatorSlug,
                }
            },
            skip() {
                return this.skipCuratorQuery
            },
        },
        artists: {
            query: getArtists,
            variables() {
                return {
                    artistSlug: this.artistSlug,
                }
            },
            skip() {
                return this.skipArtistQuery
            },
        },
        publications: {
            query: getPublications,
            variables() {
                return {
                    pubSlug: this.pubSlug,
                }
            },
            skip() {
                return this.skipPublicationQuery
            },
        },
    },
    components: {
        SlideArtist,
        SlideCurator,
        SlidePublication,

    },
    props: {
        viewPublication: {
            type: String,
            default: '',
        },
        viewArtist: {
            type: String,
            default: '',
        },
        passSlug: {
            type: String,
            default: '',
        },
        getType: {
            type: String,
            default: '',
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            artistSlug: this.artistSlug,
            curatorSlug: this.curatorSlug,
            pubSlug: this.pubSlug,
            skipArtistQuery: true,
            skipCuratorQuery: true,
            skipPublicationQuery: true,
            artistLoaded: false,
            curatorLoaded: false,
            pubLoaded: false,
        };
    },
    methods: {
        closePanel: function() {
            const panel = document?.querySelector("#slidepanel");
            panel?.classList.remove('panel-visible');
        },
        openPanel: function(slug, type) {
            const panel = document?.querySelector('#slidepanel');
            const main = document?.querySelector('main');
            const btn = document?.querySelector('#close-panel');

            if (type === 'getArtist') {
                this.artistSlug = slug;
                this.pubLoaded = false;
                this.curatorLoaded = false;
                this.artistLoaded = true;
                this.$apollo.queries.artists.refetch()
                this.skipArtistQuery = false;
            } else if (type === 'getPublication') {
                this.pubSlug = slug;
                this.artistLoaded = false;
                this.curatorLoaded = false;
                this.pubLoaded = true;
                this.$apollo.queries.publications.refetch()
                this.skipPublicationQuery = false;
            } else if (type === 'getCurator') {
                this.curatorSlug = slug;
                this.artistLoaded = false;
                this.artistLoaded = false;
                this.curatorLoaded = true;
                this.$apollo.queries.curators.refetch()
                this.skipCuratorQuery = false;
            }

            setTimeout(function() {
                panel?.classList.add('panel-visible');
            }, 0);

            main.addEventListener("click", function() {
                panel?.classList.remove('panel-visible');
            }, false);

            btn.addEventListener("click", function() {
                panel?.classList.remove('panel-visible');
            }, false);

            panel?.addEventListener("click", function(ev) {
                ev.stopPropagation();
            }, false);
        }
    }
};
</script>