<template>
  <div class="slidepanel-grid">
    <div class="slidepanel-grid--full slidepanel-title">
      <p v-if="slideContent.first_name">
        {{ slideContent.first_name }} {{ slideContent.last_name }} <span v-if="slideContent.year_of_birth">({{ slideContent.year_of_birth }})</span>
      </p>
      <p v-else>
        {{ slideContent.last_name }} <span v-if="slideContent.year_of_birth">({{ slideContent.year_of_birth }})</span>
      </p>
    </div>
    
    <ul class="slidepanel-grid--full metatext artistexhibition-grid">
      <li 
        v-for="exhibition in orderExhibitions" 
        :key="exhibition.slug"
      >
        <router-link :to="{ path: '/exhibitions/' + exhibition.slug }" id="title">
          <div class="boldtext">
              <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}: </span> <span>{{ exhibition.title }}</span> <span v-if="exhibition.subtitle">{{ exhibition.subtitle }}</span>
          </div>
          <span id="date">{{ exhibition.date_start | formatDateFull }}–{{ exhibition.date_end | formatDateFull }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        apiUrl: {
            type: String,
            default: '',
        },
        slideContent: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        orderExhibitions: function() {
            return _.orderBy(this.slideContent.rel_exh, 'date_start', 'desc')
        }
    }
}
</script>