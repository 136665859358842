<template>
    <div v-if="interviews" id="interview-overview" class="card-grid defaultcontent-grid--fullwidth">
        <h1 v-if="interviews.length > 0" class="section-header">
            {{ $t('headers.interviews') }}
            <span v-if="$route.path == '/'"><router-link
            :to="{ path: '/nitja-online' }"
            class="square-btn--default"
          >{{ $t("buttons.moreInterviews") }}</router-link></span>
        </h1>
    
        <p v-if="interviews.length > 0 && $route.path == '/nitja-online'" class="section-description">
            {{ $t('interviews.pageHeader') }}
        </p>
    
        <div id="collection">
            <router-link v-for="interview in interviews" id="item" :key="interview.id" :to="{ path: '/nitja-online/' + interview.slug }">
                <img v-if="interview.photo_cover" :src="api_url + interview.photo_cover.formats.small.url">
                <img v-else src="../../assets/images/nitja-corelogo-500px.svg" alt="Nitja senter for samtidskunst logo" class="no-interview-placeholder">
    
                <p v-if="$i18n.locale === 'nb'" id="title">
                    {{ interview.title_no }}
                </p>
                <p v-else id="title">
                    {{ interview.title_en }}
                </p>
            </router-link>
        </div>
    </div>
</template>

<script>
import LazyLoad from 'vanilla-lazyload'

export default {
    props: {
        interviews: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    mounted() {
        this.componentLazyLoad = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 10,
        });
    }
};
</script>
