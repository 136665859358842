<template>
    <div v-if="ifUpcoming.length > 0" id="exhibitions-upcoming" class="card-grid defaultcontent-grid--fullwidth">
        <h1 class="section-header">
            {{ $tc("headers.upcomingExhibitions", ifUpcoming.length) }}
        </h1>
        <div id="collection">
            <router-link v-for="exhibition in ifUpcoming" id="item" :key="exhibition.slug" :to="{ path: '/exhibitions/' + exhibition.slug }">
                <img v-if="exhibition.photo_cover" :src="api_url + exhibition.photo_cover.formats.small.url">
                <img v-else src="../../assets/images/nitja-corelogo-500px.svg" alt="Nitja senter for samtidskunst logo" class="no-exhibition-placeholder">
    
                <p id="title">
                    <span v-if="exhibition.title_main_artist">{{ exhibition.title_main_artist }}: </span>
                    <span>{{ exhibition.title }}</span>
                    <span v-if="exhibition.subtitle"> {{ exhibition.subtitle }}</span>
                </p>
                <p id="date">
                    {{ exhibition.date_start | formatDateFull }}–{{ exhibition.date_end | formatDateFull }}
                </p>
            </router-link>
        </div>
    </div>
</template>

<script>
import { format, differenceInDays } from 'date-fns'

export default {
    props: {
        exhibitions: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            filteredExhibitions: 0,
        };
    },
    computed: {
        today() {
            return format(new Date(), "yyyy-MM-dd")
        },
        ifUpcoming: function() {
            let filteredExhibitions = this.exhibitions.filter((exhibition) => { return exhibition.date_start > this.today });
            return filteredExhibitions;
        },
    },
    methods: {
        closingSoon: function(value) {
            return differenceInDays(new Date(), new Date(value));
        }
    }
};
</script>
