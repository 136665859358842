<template>
  <section id="artists-overview">
    <ArtistsList :artists="artists" />
    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>
  </section>
</template>

<script>
import ArtistsList from '../components/artists/ArtistsList.vue'
import gql from 'graphql-tag'

const getArtists = gql ` 
  query Artists {
    artists(limit: -1) {
      first_name
      last_name
      slug
  }
  }
`;

export default {
    apollo: {
        artists: {
            query: getArtists,
            loadingKey: 0,
            debounce: 5000
        }
    },
    components: {
        ArtistsList,
    },
    data() {
        return {
            artists: [],
        };
    },

};
</script>
