<template>
  <section
    v-if="exhibitions"
    id="archive-page"
  >
    <ExhibitionPast :exhibitions="exhibitions" />
    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>
    <!-- <div class="spinner">{{ $t('headers.loading') }}</div> -->
  </section>
</template>

<script>
import ExhibitionPast from '../components/exhibitions/ExhibitionPast';
import gql from "graphql-tag";

const getExhibitions = gql `
  query Exhibitions {
    exhibitions(sort: "date_start:asc") {
      id
      slug
      title
      subtitle
      date_start
      date_end
      contentShort_no
      contentShort_en
      photo_cover {
        formats
      }
      artists(where: {extra_artist_category_null: true }) {
        first_name
        last_name
        extra_artist_category
      }
    }
  }
`;

const getArtists = gql `
  query Artists {
    artists {
      first_name
      last_name
      extra_artist_category
    }
  }
`;

export default {
    apollo: {
        exhibitions: {
            query: getExhibitions
        },
        artists: {
            query: getArtists
        }
    },
    components: {
        ExhibitionPast
    },
    data() {
        return {
            exhibitions: []
        };
    },
    metaInfo() {
        return { title: this.$t("nav.archive") }
    }
}
</script>